import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '../../components/Layout';
import ContentLayout from '../../components/ContentLayout';
import AcademyNavigation from '../../components/academy/AcademyNavigation';
import ProgramNavigation from '../../components/academy/ProgramNavigation';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <ContentLayout title="Web Development Program" subTitle="Career Services" nav={<AcademyNavigation />} subNav={<ProgramNavigation />}>
    {children}
  </ContentLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Career Services`}</h3>
    <p>{`Career support services are including, but not limited to, the
following:`}</p>
    <ul>
      <li parentName="ul">{`Curriculum including lectures, mentoring, and coaching on interview techniques, decoding technical job descriptions, resume writing workshops, and portfolio building.`}</li>
      <li parentName="ul">{`Hosted graduate events, inviting potential employers, advisory board members, and community leaders to showcase the skills and work done by recent graduates.`}</li>
      <li parentName="ul">{`Host guest lectures from community leaders on topics that add to and enhance the core program content.`}</li>
      <li parentName="ul">{`The school maintains a "reverse job board", used by local employers and hiring partners that lists graduates and alumni who are seeking employment.`}</li>
    </ul>
    <p>{`Students are also encouraged to participate and attend developer
dozens of community events hosted on campus and around town during
their time in the program. Relationships formed at these events often
lay the seeds for future employment opportunities.`}</p>
    <article className="message is-warning">
  <div className="message-body">
    While every effort is made by our team to prepare students for their first
    job as a junior web developer, and to connect them with employers and hiring
    partners through relationships our staff and faculty have built in the
    community, the institution does not guarantee employment.
  </div>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      